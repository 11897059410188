import * as React from "react";
const NotExistLogo = (props) => (
  <svg
    width={90}
    height={90}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M96.8704 62.0203C97.5154 61.3753 97.914 60.4829 97.9135 59.5007C97.914 57.5301 96.3213 55.9374 94.3507 55.9378L26.6498 55.9367C24.6797 55.9367 23.087 57.5293 23.087 59.4994C23.0866 61.47 24.6793 63.0627 26.6499 63.0623L94.3508 63.0634C95.3386 63.063 96.2254 62.6653 96.8704 62.0203Z"
      fill="#FF9900"
    />
    <path
      d="M114 54.0001C110.4 54.0001 108 56.4001 108 60.0001C108 86.4001 86.4001 108 60.0001 108C33.6 108 12 86.4001 12 60.0001C12 47.4001 16.8 35.4001 25.8 26.4C34.8 16.8 46.8001 12 60.0001 12C63.6001 12 67.8001 12.6 71.4001 13.2C74.4001 14.4 78.0001 12.6 79.2001 9.00001C80.4001 5.40001 78.0001 3 75.0001 1.8H74.4001C69.6001 0.600001 64.8001 0 60.0001 0C27 0 0 27 0 60.6001C0 76.2001 6.60001 91.8001 17.4 102.6C28.8 114 43.8001 120 59.4001 120C92.4001 120 119.4 93.0001 119.4 60.0001C120 56.4001 117 54.0001 114 54.0001Z"
      fill="#FF9900"
    />
  </svg>
);
export default NotExistLogo;
