import * as React from "react";
const RedLogo = (props) => (
  <svg
    width={90}
    height={90}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M84.4355 86.9998C85.3476 86.9998 86.2605 86.6507 86.9547 85.9558C88.3484 84.5627 88.3484 82.3102 86.9547 80.9172L39.0838 33.0446C37.6907 31.6515 35.4383 31.6515 34.0453 33.0446C32.6516 34.4377 32.6516 36.6901 34.0453 38.0832L81.9162 85.9558C82.615 86.6539 83.5233 86.9998 84.4355 86.9998Z"
      fill="#D8232A"
    />
    <path
      d="M36.5645 86.9998C37.4767 86.9998 38.3889 86.6507 39.0838 85.9558L86.9547 38.0832C88.3484 36.6901 88.3484 34.4377 86.9547 33.0446C85.5617 31.6515 83.3099 31.6515 81.9162 33.0446L34.0453 80.9172C32.6516 82.3102 32.6516 84.5627 34.0453 85.9558C34.7402 86.6539 35.6524 86.9998 36.5645 86.9998Z"
      fill="#D8232A"
    />
    <path
      d="M114 53.9998C110.4 53.9998 108 56.3998 108 59.9998C108 86.3999 86.4001 108 60.0001 108C33.6 108 12 86.3999 12 59.9998C12 47.3998 16.8 35.3998 25.8 26.3998C34.8 16.7998 46.8001 11.9998 60.0001 11.9998C63.6001 11.9998 67.8001 12.5998 71.4001 13.1998C74.4001 14.3998 78.0001 12.5998 79.2001 8.99977C80.4001 5.39976 78.0001 2.99976 75.0001 1.79976H74.4001C69.6001 0.599756 64.8001 -0.000244141 60.0001 -0.000244141C27 -0.000244141 0 26.9998 0 60.5998C0 76.1999 6.60001 91.7999 17.4 102.6C28.8 114 43.8001 120 59.4001 120C92.4001 120 119.4 92.9999 119.4 59.9998C120 56.3998 117 53.9998 114 53.9998Z"
      fill="#D8232A"
    />
  </svg>
);
export default RedLogo;
