import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import Logo from "../../assets/logo";
import BigIcon from "../../assets/BigLogo";
import BurgerMenu from "../../assets/BurgerMenu";
import CloseIcon from '../../assets/CloseIcon';

const Head = () => {
  const head_data = [
    {
      txt: 'Search Page',
      to: "/"
    },
    {
      txt: 'About us',
      to: "/aboutus"
    },
    {
      txt: 'Donate',
      to: "https://www.egyptianrc.org/Arabic/home"
    },
    {
      txt: "Contact us",
      to: "/contactus"
    }
  ];

  const location = useLocation();
  const [selected, setSelected] = useState(false);
  return (

    <>
      <header className="container flex mx-auto justify-between">

        <div className="md:justify-normal md:m-5 md:ml-16  ml-3">
          <div>
            <Logo />
          </div>
        </div>


        <div className="md:flex md:justify-center  md:w-[120rem] hidden">
          {head_data.map((item, index) => (
            <Link
              className={` pl-8 font-bold  self-center   text-[1.1rem] cursor-pointer text-[${location.pathname === item.to ? '#009639' : '#777777'}]`}
              to={item.to}
            >
              {item.txt}
            </Link>
          ))}
        </div>


        {
          selected==true ? (
            <>
              <div
                onClick={() => {
                  setSelected(false);
                }}
                className="md:hidden justify-center self-center mr-3">

                <div style={{ display: selected===false ? 'none' : null }} className="md:hidden">
                  
                  <CloseIcon />
                </div>
              </div>
            </>
          ) :
            <>
              <div
                onClick={() => {
                  setSelected(true);
                }}
                className="md:hidden justify-center self-center mr-3">

                <div style={{ display: selected ? 'none' : null }} className="md:hidden">
                  <BurgerMenu />
                </div>
              </div>
            </>
        }
      
      </header>

      <div className="container mx-auto">
        <div style={{ display: selected === false ? 'none' : null }} className="md:hidden w-screen h-[50rem] absolute bg-[#f6f8fc]">

          {head_data.map((item, index) => (
            <Link
              onClick={()=>{
                 setSelected(false);
              }}
              className="border-b block text-[1rem] p-4 border-[#c8d7ee]"
              to={item?.to}
            >
              {item.txt}
            </Link>
          ))}

        </div>
      </div>


    </>



  );
};

export default Head;
