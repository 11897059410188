import React, { useState } from "react";
import Head from "../Header/Head";
import axios from "axios";
import { apiUrl } from "../../env";
import { Alert } from "@mui/material";

const ContactUs = () => {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");

    const [nameErr, setNameErr] = useState("");
    const [emailErr, setEmailErr] = useState("");
    const [messageErr, setMessageErr] = useState("");
    const [showAlert, setShowAlert] = useState(false);

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
    };

    const handleNameChange = (e) => {
        const value = e.target.value;
        setName(value);
        if (!value) {
            setNameErr("Name is required");
        } else {
            setNameErr("");
        }
    };

    const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        if (!value) {
            setEmailErr("Email is required");
        } else if (!validateEmail(value)) {
            setEmailErr("Invalid email address");
        } else {
            setEmailErr("");
        }
    };

    const handleMessageChange = (e) => {
        const value = e.target.value;
        setMessage(value);
        if (!value) {
            setMessageErr("Message is required");
        } else {
            setMessageErr("");
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!name) {
            setNameErr('Name is required');
        }
        if (!email) {
            setEmailErr('Email is require');
        }
        if (!message) {
            setMessageErr('Message is required');
        }
        let data = {
            name: name,
            email: email,
            message: message
        }
        axios.post(`${apiUrl}user/contact-us`, data).then((res) => {
            if (res.status === 200) {
                setShowAlert(true);
            }
        }).catch((err) => {
            // console.log(err);
        });
    };

    return (
        <>
            <Head />
            <div className="container mx-auto">
                <div className="text-[#009639] md:text-[2rem] text-[1.4rem] font-bold md:ml-[4.4rem] ml-4 mt-7">
                    Contact Us
                </div>

                <form onSubmit={handleSubmit}>
                    <div className="md:ml-[7rem] ml-5  mt-[2rem]">
                        <div>Name</div>
                        <input
                            style={{ borderColor: nameErr ? '#f00' : "#8D8D8D" }}
                            type="text"
                            value={name}
                            onChange={handleNameChange}
                            className={`md:w-[40rem] w-[19rem]   md:pt-3 p-2 outline-none border-b border-[${nameErr ? '#f00' : '#8D8D8D'}]`}
                        />
                        {nameErr && <p className="text-red-500">{nameErr}</p>}
                    </div>

                    <div className="md:ml-[7rem] ml-5 mt-[2rem]">
                        <div>Email</div>
                        <input
                            style={{ borderColor: emailErr ? '#f00' : "#8D8D8D" }}
                            type="email"
                            value={email}
                            onChange={handleEmailChange}
                            className={`md:w-[40rem] w-[19rem] pt-3 outline-none border-b border-[${emailErr ? '#f00' : '#8D8D8D'}]`}
                        />
                        {emailErr && <p className="text-red-500">{emailErr}</p>}
                    </div>

                    <div className="md:ml-[7rem]  ml-5 mt-[3rem]">
                        <div>Message</div>
                        <input
                            style={{ borderColor: messageErr ? '#f00' : "#8D8D8D" }}
                            value={message}
                            onChange={handleMessageChange}
                            className={`md:w-[60rem] w-[22rem] pt-3 outline-none border-b border-[${messageErr ? '#f00' : '#8D8D8D'}]`}
                        />
                        {messageErr && <p className="text-red-500">{messageErr}</p>}
                    </div>
                    {
                        showAlert ? (
                            <>
                                <div className="w-[20rem] m-auto p-3">
                                    <Alert variant="filled"  severity="success">
                                        Thanks For Contact
                                    </Alert>
                                </div>

                            </>
                        ) : null
                    }
                    <div className="md:ml-[7rem] ml-4 md:mt-[4rem] mt-[2rem]">
                        <button onClick={handleSubmit} type="submit" className="bg-[#000000] text-white px-4 py-2 rounded-md">
                            Submit
                        </button>

                    </div>

                </form>


            </div>


        </>
    );
};

export default ContactUs;
