import React, { useEffect, useState } from "react";
import Boycott from "../../assets/Boycott";
import RedLogo from "../../assets/RedLogo";
import NotExistLogo from "../../assets/NotExistLogo";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Result = (props) => {
  return (
    <>
      {
        props.support === true ? (
          <>
            <div className=" p-[3rem] w-full -z-20  bg-[#F4433617] h-[14rem] absolute bottom-0 left-0  flex items-center justify-center" >
              <div class="container mx-auto">
                <div className="w-full flex md:flex-row items-center justify-center gap-[2rem]  ">
                  <div >
                    <RedLogo />
                  </div>

                  <div >
                    <p className="md:text-[2rem] text-[1rem] font-bold text-[#D8232A]">
                      This Product Supports-
                      <span style={{ textDecoration: "line-through" }}>Iسrael</span>
                    </p>
                    <p className="md:text-[2rem] text-[1rem] font-bold text-[#D8232A]">هذه الشركة تدعم الاحتلال الإسرائيلي</p>
                    <p className="md:text-[1.2rem] text-[0.8rem]">As you like, but your decision will make a difference</p>
                    <p className="md:text-[1.2rem] text-[1rem]">in the <span className="font-bold">Palestinian issue.</span></p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : props.support === false ? (
          <>
            <div className=" p-[3rem] w-full -z-20  bg-[#00963917] h-[14rem] absolute bottom-0 left-0  flex items-center justify-center" >
              <div class="container mx-auto">
                <div className="w-full flex md:flex-row items-center justify-center gap-[2rem]  ">
                  <div >
                    <Boycott />
                  </div>

                  <div >
                    <p className="md:text-[1.2rem] text-[0.8rem]">Thanks For supporting Palestine</p>
                    <p className="md:text-[2rem] text-[1rem] font-bold text-[#009639]">
                      This Product Boycotts-
                      <span style={{ textDecoration: "line-through" }}>Iسrael</span>
                    </p>
                    <p className="md:text-[2rem] text-[1rem] font-bold text-[#009639]">هذه الشركة تقاطع الاحتلال الإسرائيلي</p>
                    <p className="md:text-[1.2rem] text-[1rem]">You can use it with Peace and love. </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : props.support === "notexist" ? (
          <>
            <div className=" p-[3rem] w-full -z-20  bg-[#FF990017] h-[14rem] absolute bottom-0 left-0  flex items-center justify-center" >
              <div class="container mx-auto">
                <div className="w-full flex md:flex-row items-center justify-center gap-[2rem]  ">
                  <div >
                    <NotExistLogo />
                  </div>
                  <div >
                    <p className="md:text-[2rem] text-[1.2rem] font-bold text-[#FF9900]">
                      Sorry We Don’t <br /> Have Data About This Product
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : null
      }
    </>

  );
};

export default Result;

