import React from "react";

function Boycott() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="90"
      fill="none"
      viewBox="0 0 120 120"
    >
      <path
        fill="#009639"
        d="M46.2 55.8c-2.4-2.4-6-2.4-8.4 0-2.4 2.4-2.4 6 0 8.4l18 18C57 83.4 58.2 84 60 84c1.8 0 3-.6 4.2-1.8l42-48c1.8-3 1.8-6.6-1.2-8.4-2.4-1.8-6-1.8-7.8.6L60 69 46.2 55.8z"
      ></path>
      <path
        fill="#009639"
        d="M114 54c-3.6 0-6 2.4-6 6 0 26.4-21.6 48-48 48S12 86.4 12 60c0-12.6 4.8-24.6 13.8-33.6 9-9.6 21-14.4 34.2-14.4 3.6 0 7.8.6 11.4 1.2 3 1.2 6.6-.6 7.8-4.2C80.4 5.4 78 3 75 1.8h-.6C69.6.6 64.8 0 60 0 27 0 0 27 0 60.6c0 15.6 6.6 31.2 17.4 42C28.8 114 43.8 120 59.4 120c33 0 60-27 60-60 .6-3.6-2.4-6-5.4-6z"
      ></path>
    </svg>
  );
}

export default Boycott;
