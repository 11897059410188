import React from "react";
import Head from "./Component/Header/Head";
import Card from "./Component/Card/Card";
import Result from "./Component/Result/Result";
import './App.css';
import { Routes, Route } from "react-router-dom";
import AboutUs from "./Component/Aboutus/AboutUs";
import ContactUs from "./Component/ContactUs/ContactUs";


function App(props) {
  return (
    <>
      <Routes>
        <Route path="/" element={<Card />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/contactus" element={<ContactUs/>}/>
      </Routes>
    </>
  );
}

export default App;
