import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { apiUrl } from "../../env";
import { Link } from 'react-router-dom';
import Result from "../Result/Result";
import Head from "../Header/Head";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Card = () => {
  const [loading, setLoading] = useState('');
  const modalRef = useRef(null);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [category_id, setCategory_id] = useState(8);
  const [name, setName] = useState('');
  const [filter_data, setFilterData] = useState([]);
  const [detectedLanguage, setDetectedLanguage] = useState('');
  const [support, setSupport] = useState(null);
  const [modal_display, setModalDisplay] = useState('');
  const [name_err, setNameErr] = useState(false);
  const [product_id, setProductId] = useState(7);
  const [selected, setSelected] = useState(false);
  

  const handleSubmit = (Name) => {
    if (!Name) {
      setNameErr(true);
    
      return
    } else {
      let data = {
        name: Name
      }
      axios.post(`${apiUrl}user/product/result`, data).then((res) => {
        if (res.data.data?.support === "1") {
          setSupport(true);
        } else {
          setSupport(false);
        }
      }).catch((err) => {
        setSupport('notexist');
        // console.log(err);
      });
      setSelected(false);
    }
  };


  const detectLanguage = (text) => {
    const arabicPattern = /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]+/;
    if (arabicPattern.test(text)) {
      setDetectedLanguage('arabic');
    } else {
      setDetectedLanguage('english');
    }
  };

  const handleClick = (item, index) => {

    setCategory_id(item);
    setSelectedCategory(index);
  };

  const GetCategories = () => {
    axios.get(`${apiUrl}user/category/get-category`).then((res) => {
      setCategories(res.data?.data);
    }).catch((err) => {
      // console.log(err);
    });
  }

  const Search = () => {
    let data = {
      category_id: category_id,
      name: name,
      key: detectedLanguage
    }
    axios.post(`${apiUrl}user/product/search_product`, data).then((res) => {
      setFilterData(res.data?.data);
      setLoading(false);
      setSupport('');
    }).catch((err) => {
      // console.log(err);
    })
  }

  useEffect(() => {
    GetCategories();
  }, [selectedCategory, product_id]);

  useEffect(() => {
    const closeModalOnOutsideClick = (event) => {
      if (modal_display && modalRef.current && !modalRef.current.contains(event.target)) {
        setModalDisplay(false);
      }
    }
    document.addEventListener('click', closeModalOnOutsideClick);
    return () => {
      document.removeEventListener('click', closeModalOnOutsideClick);
    };
  }, [modal_display]);


  return (
    <>
      <div className="container mx-auto">
        <Head />
        <div className="md:gap-3 md:w-[59.4rem] md:h-[14.4rem] justify-center m-auto rounded-3xl border-2  border-[#d0d0d0] w-[22rem] h-[9.5rem] self-center ">
          <div className="text-center md:text-[2.2rem] font-bold md:p-[1.3rem] p-[1rem] text-[#009639] text-[1rem]">
            Find Boycott-
            <span style={{ color: "#D8232A", textDecoration: "line-through" }}>
              Iسrael
            </span>{" "}
            Products Easily
          </div>

          <div className="flex md:w-[90%] w-[94%]  justify-between m-auto md:text-[1.3rem] text-black text-[0.6rem] md:font-normal font-bold cursor-pointer">
            {categories?.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  // setLoading(true);
                  Search();
                  handleClick(item?.id, index);
                }}
                style={{ color: selectedCategory === index ? '#009639' : 'black' }}
              >
                {item?.name}
              </div>
            ))}
          </div>

          <div style={{ borderColor: name_err ? '#f00' : "#d0d0d0" }} className={`md:w-[56.3rem] md:h-[3.8rem] w-[20.4rem] h-[2.6rem] m-auto mt-5 flex justify-between md:rounded-3xl rounded-lg   border-2`}>
            <input
              value={name}
              onChange={(val) => {
                if (!val.target.value) {
                  setNameErr(true);
                } else {
                  setNameErr(false);
                }
                setLoading(true);
                setName(val.target.value);
                setModalDisplay(true);
                detectLanguage(val.target.value);
                Search();
              }}
              style={{ touchAction: "manipulation" }}
              placeholder="Type Brand Name .."
              className="md:w-[38rem] md:h-[2.5rem] h-[1.4rem]  w-[11rem]  m-2 border-none outline-none md:ml-5 ml-3"
            />
            <button
              onClick={() => {
                setNameErr(false);
                handleSubmit(name)
              }}
              className="bg-[#009639] border-none text-[#ffffff] md:w-[7.5rem] md:h-[2.5rem] h-[1.8rem] w-[5.4rem] md:rounded-lg rounded-lg font-bold cursor-pointer self-center mr-3 md:text-[1rem]" type="submit">
              {loading ? (
                <>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress color="inherit" size={20} />
                  </Box>
                </>) : <>
                Search
              </>}
            </button>
          </div>



          <div ref={modalRef} style={{ display: modal_display === false ? 'none' : null }} className="md:w-[56rem] w-[20.4rem] h-50  bg-[#ffffff] m-auto  rounded-md">
            {
              filter_data?.map((item, index) => (
                <>
                  <div
                    onClick={() => {
                      setName(item?.name);
                      setSelected(true);
                      setProductId(item?.id);
                      setModalDisplay(false);
                      handleSubmit(item?.name);
                    }} 
                    className="md:w-[56rem] w-[20.4rem] h-10 mt-1 p-3 pl-[3rem] font-600  hover:bg-[#e1e1e2] cursor-pointer" >
                    {item?.name}
                  </div>
                </>
              ))
            }
          </div>

        </div>


        <Result  support={support} />

      </div>





    </>



  );
};

export default Card;

