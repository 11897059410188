import React from "react";
import Head from "../Header/Head";



const AboutUs = () => {
    return (
        <>
            <Head />
            <div className="container mx-auto">
                <div className="mx-auto ">
                    <div className=" text-[#009639]  md:text-[2rem] text-[1.4rem] m-3 font-bold">
                        About Us
                    </div>

                    <div className="md:h-[6rem] h-[7.3rem] md:text-[1.5rem] text-[0.9rem] p-3">
                        At <span className="text-[#009639]">Palesfound</span>, we stand in solidarity with Palestine and its quest for justice and freedom. Our platform is driven by a commitment to raise awareness and support the Palestinian cause through ethical consumer choices.
                    </div>

                    <div className="md:h-[8rem] h-[10rem] md:text-[1.5rem] text-[0.9rem] p-3" >
                        We offer a curated selection of products that align with the principles of supporting Palestine and boycotting <span style={{ textDecoration: 'line-through' }}>Iسrael</span> goods. Our mission is to empower individuals who seek to make informed decisions about their purchases, ensuring that their choices echo their support for justice, human rights, and a peaceful resolution in the region.

                    </div>

                    <div className="md:h-[8rem] h-[10rem] md:text-[1.5rem] text-[0.9rem] p-3">
                        By choosing products featured on our platform, you are making a tangible difference and contributing to the global effort to support Palestine. We believe that ethical consumption is a powerful tool for change,and we're honored to be part of your journey in supporting this important cause.
                    </div>

                    <div className="md:h-[4 rem] h-[4rem] md:text-[1.5rem] text-[0.9rem] p-3">
                        Join us as we unite for a more just and equitable world, one product at a time.
                    </div>

                    <div className="md:h-[3rem]  md:text-[1.5rem] text-[0.9rem] text-[#009639] p-3 ">
                        Thank you for being a part of our community and for supporting the Palestinian people.
                    </div>

                    <div >
                        <div className="p-3 md:text-[1.5rem] md:h-[3rem] h-[2rem] text-[#009639]">
                            Team work
                        </div>

                        <div className="p-3">

                            <div className="flex">
                                <div className="font-bold text-[#009639] text-[0.8rem] ">
                                    Idea:
                                </div>

                                <div className="font-bold text-[#000] pl-1  text-[0.8rem]">
                                    Farah El Newehy
                                </div>
                            </div>


                            <div className="flex">
                                <div className="font-bold text-[#009639] text-[0.8rem] ">
                                    Ui/Ux Design:
                                </div>

                                <div className="font-bold text-[#000] pl-1  text-[0.8rem]">
                                    Ahmed Baradei
                                </div>
                            </div>


                            <div className="flex">
                                <div className="font-bold text-[#009639]  text-[0.8rem]">
                                    Back-end:
                                </div>

                                <div className="font-bold text-[#000] pl-1 text-[0.8rem] ">
                                    Mohamed El Sawy
                                </div>
                            </div>

                            <div className="flex">
                                <div className="font-bold text-[#009639] text-[0.8rem] ">
                                    Front-end:
                                </div>

                                <div className="font-bold text-[#000] pl-1  text-[0.8rem]">
                                    Mohamed El Sawy & Mohamed Sharaf
                                </div>
                            </div>
                        </div >
                    </div>
                </div>

             
            </div>
        </>
    );
}

export default AboutUs;